<template>
  <div class="list_display" :style="{'font-size': fontSize + 'px'}">
    <a-spin :spinning="spinning">
      <div class="list_display_wrapper">
        <div class="wrapper-item header" v-if="dataList.length > 0">
          <div :class="['title', isPie ? 'threeWidth': 'twoWidth', 'titlecolor' ]">名称</div>
          <div class="name_box bigWidth titlecolor">{{ getField }}</div>
          <div class="name_box minWidth titlecolor" v-if="isPie">占比</div>
        </div>

        <template v-if="dataList.length > 0">
          <div class="wrapper-item item" v-for="(item, index) in dataList" :key="index">
            <div :class="['name', isPie ? 'threeWidth': 'twoWidth', 'concolor' ]">
              <span class="color" :style="{ 'background': colorList[index] }"></span>
              <a-tooltip :title="`${item[fild]}`">
                <div class="name-box" :title="`${item[fild]}`">{{ item[fild] || '其他' }}</div>
              </a-tooltip>
            </div>
            <div class="name_box bigWidth concolor">
              <a-tooltip :title="`${getField}：${isMoney ? moneyType : ''}${numFormat(item[formquery.code], 3, '')}${isPercentage}`">
                <span class="isMoney" v-if="isMoney">{{ moneyType }}</span>
                <span>{{ numFormat(item[formquery.code], 3, '') || 0 }}{{ isPercentage }}</span>
              </a-tooltip>
            </div>
            <div class="name_box minWidth concolor" v-if="isPie">
              <a-tooltip
                :title="`${getField}：${
                  (((item[formquery.code] / allData[getSum]) * 100).toFixed(2))} %`"
              >
                <span>{{ ((item[formquery.code] / allData[getSum]) * 100).toFixed(2) || 0 }} %</span>
              </a-tooltip>
            </div>
          </div>
        </template>

        <div class="empty" v-else></div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { platData, trafficChart, trafficChartForSadmin } from '@/apiForManage/dashboard'
import { numFormat } from '@/utils/dealNumber'
import { mapState } from 'vuex'
import autoFontSize from '@/mixins/autoFontSize'

export default {
  mixins: [autoFontSize],
  props: {
    formquery: {
      type: Object,
      default: () => ({})
    },
    // 1: 流量排行列表，2: 预算排行列表
    type: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      spinning: false,
      colorList: ['#61A4E4', '#16DBCC', '#FFBB38', '#FF82AC', '#61A4E4', '#7161C5'],
      dataList: [],
      allData: {},
      arrList: [
        { name: '竞胜率', id: 'bidSuccessRate', sum: 'sumBidSuccessRate' },
        { name: '点击率', id: 'clickRate', sum: 'sumClickRate' },
        { name: '利润率', id: 'profitRate', sum: 'sumProfitRate' },
        { name: 'eCPM', id: 'ecpm', sum: 'sumEcpm' },
        { name: 'eCPM', id: 'advEcpm', sum: 'sumAdvEcpm' },
        { name: '流量请求', id: 'request' },
        { name: '竞胜率', id: 'requestFilledRate' },
        { name: '展示率', id: 'impressRate' },
        { name: '利润', id: 'profit' },
        { name: '收入', id: 'advPrice', sum: 'sumIncome' },
        { name: '展示', id: 'impress', sum: 'sumImpress' },
        { name: '点击', id: 'click', sum: 'sumClick' }
      ]
    }
  },
  computed: {
    ...mapState({
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥',
      role: (state) => state.user.roles[0]
    }),
    isSAdmin () {
      return this.role === 'SUPER_ADMIN'
    },
    isMoney () {
      const rotaPropList = [
        'advPrice',
        'profit',
        'ecpm',
        'advEcpm',
        'sumIncome'
      ]
      return rotaPropList.includes(this.formquery.code)
    },
    fild () {
      // 1: 流量排行 名称取publishName，2: 预算排行 名称取platName
      return this.type === 1 && this.isSAdmin ? 'accountName' : this.type === 1 ? 'publishName' : 'platName'
    },
    isPie () {
      // 若是预算排行列表展示 且 是以下字段展示占比，否则都没有占比字段
      const rotaPropList = [
        'advPrice',
        'impress',
        'click'
      ]
      return rotaPropList.includes(this.formquery.code) && this.type === 2
    },
    isPercentage () {
      // 若是预算排行列表展示 且 是以下字段展示占比，否则都没有占比字段
      const rotaPropList = [
        'bidSuccessRate',
        'clickRate',
        'profitRate',
        'requestFilledRate',
        'impressRate'
      ]
      return rotaPropList.includes(this.formquery.code) ? '%' : ''
    },
    getSum () {
      const sum = this.arrList.find((item) => item.id === this.formquery.code).sum
      return sum
    },

    getField () {
      const typeList = this.arrList
      const name = typeList.find((item) => item.id === this.formquery.code).name
      return name || ''
    }
  },
  created () {
  },
  mounted () {},
  destroyed () {},
  watch: {
    formquery: {
      handler (val, oldVal) {
        this.$nextTick(() => {
          this.getData()
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    numFormat,
    async getData () {
      this.dataList = []
      this.spinning = true
      const apiList = {
        1: trafficChart,
        2: platData,
        3: trafficChartForSadmin
      }
      const type = this.isSAdmin && this.type === 1 ? 3 : this.type
      const resp = await apiList[type](this.formquery)
      const { data = {} } = resp
      this.allData = data
      this.dataList = data.list || []
      this.spinning = false
    }
  }
}
</script>

<style lang="less" scoped>
.list_display {
  font-family: PingFang SC, PingFang SC;
  height: 100%;
  padding: calc(14em / 14) 0;
  ::v-deep {
    .ant-spin-nested-loading {
      height: 100%;
      position: relative;
      .ant-spin-container {
        width: 100%;
        height: 100%;
        .list_display_wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .wrapper-item {
            flex-grow: 1;
            position: relative;
            display: flex;
            border-bottom: 1px solid rgba(21, 34, 50, 0.08);
            font-size: calc(14em / 14);
            .threeWidth {
              width: 38%;
            }
            .titlecolor {
              font-weight: 500;
              color: #5A607F;
            }
            .concolor {
              font-weight: 400;
              color: #344563;
            }
            .bigWidth {
              width: 35%;
            }
            .twoWidth {
              width: 65%;
            }
            .minWidth {
              width: 28%;
            }
            .isMoney {
              margin-right: calc(4em / 14);
            }
            .name {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .color {
                width: calc(14em / 14);
                height: calc(14em / 14);
                border-radius: calc(4em / 14);
                margin-right: calc(14em / 14);
              }
            }
            .name_box {
              display: flex;
              align-items: center;
              cursor: pointer;
            }
            &.header {
              height: calc(20em / 14);
              font-size: calc(14em / 14);
              .title {
                display: flex;
                align-items: center;
                font-weight: 500;
              }
            }
            &.item {
              .name {
                justify-content: flex-start;
                .name-box {
                  width: 70%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  cursor: pointer;
                  text-align: left;
                }
              }
            }
          }
          .wrapper-item:last-child {
            border-bottom: none;
          }
          .empty{
            width: 100%;
            height: 100%;
            background-image: url('/images/nodata_margex.png');
            background-size: 70%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
}
</style>
