<template>
  <div class="company-count-container">
    <a-spin :spinning="spinning" >
      <div v-show="hasData" class="chart">
        <div class="chart" ref="chart"></div>
      </div>
      <div class="empty" v-show="!hasData"></div>
    </a-spin>
  </div>
</template>

<script>
import { platData } from '@/apiForManage/dashboard'
import option from './options'
import { mapState } from 'vuex'
import * as echarts from 'echarts'
import { numFormat } from '@/utils/dealNumber'

export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      option: {},
      myEcharts: undefined,
      spinning: false,
      hasData: true
    }
  },
  computed: {
    ...mapState({
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥'
    })
  },
  created () {
    // parse返回Object类型, 对应给定JSON文本的对象/值。
    this.option = JSON.parse(JSON.stringify(option))
  },
  mounted () {
    this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  watch: {
    query: {
      handler (val, oldVal) {
        this.$nextTick(() => {
          this.getChartData()
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    },
    initChart () {
      this.myEcharts.setOption(this.option)
    },
    async getChartData () {
      this.spinning = true
      const code = this.query.code
      const typeList = [
        { name: '收入', id: 'advPrice', sum: 'sumIncome' },
        { name: '展示', id: 'impress', sum: 'sumImpress' },
        { name: '点击', id: 'click', sum: 'sumClick' },
        { name: 'ecpm', id: 'advEcpm', sum: 'sumAdvEcpm' }
      ]
      const index = typeList.find((item) => item.id === code).name
      const sum = typeList.find((item) => item.id === code).sum
      const resp = await platData(this.query)
      const data = resp.data.list
      this.hasData = !!data.length
      this.option.tooltip.formatter = (params) => {
        const moneyType = ['advEcpm', 'advPrice'].includes(code) ? this.moneyType : ''
        const one = params.marker + '平台：' + `${ params.name || '其他' }` +
          '<br/>&nbsp;&nbsp;&nbsp;' + `${ index }` + '：' +
          numFormat(params.data.value, 3, '')
        const two = params.marker + '平台：' + `${ params.name || '其他' }` +
          '<br/>&nbsp;&nbsp;&nbsp;' + `${ index }` + '：' +
          moneyType + numFormat(params.data.value, 3, '') +
          '<br/>&nbsp;&nbsp;&nbsp;占比：' +
          (!params.data.value ? 0 : sum !== 0 ? ((params.data.value / resp.data[sum]) * 100).toFixed(2) : 0) +
          '%'
        const str = code === 'advEcpm' ? one : two
        return (
          str
        )
      }
      this.option.series[0].itemStyle = {
        borderRadius: [10, 10, 10, 10],
        borderColor: '#fff',
        borderWidth: 6,
        color: (params) => {
          const colorList = [
            {
              c1: 'rgba(183, 105, 247, 1)',
              c2: 'rgba(138, 89, 251, 1)'
            },
            {
              c1: 'rgba(95, 233, 196, 1)',
              c2: 'rgba(62, 219, 223, 1)'
            },
            {
              c1: 'rgba(249, 169, 110, 1)',
              c2: 'rgba(248, 123, 104, 1)'
            },
            {
              c1: 'rgba(250, 84, 124, 1)',
              c2: 'rgba(247, 65, 108, 1)'
            },
            {
              c1: 'rgba(22, 178, 246, 1)',
              c2: 'rgba(31, 125, 238, 1)'
            },
            {
              c1: 'rgba(120, 166, 234, 1)',
              c2: 'rgba(120, 146, 214, 1)'
            }
          ]
          return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              // 左、下、右、上
              offset: 0,
              color: colorList[params.dataIndex].c1
            },
            {
              offset: 1,
              color: colorList[params.dataIndex].c2
            }
          ])
        }
      }
      if (data.length > 0) data[data.length - 1].platName = '其他'
      this.option.series[0].data = data
        ? data.map((item, i) => {
            return {
              value: item[code],
              name: item.platName
            }
          })
        : [
            {
              value: 0,
              name: ''
            }
          ]
      this.initChart()
      this.spinning = false
    }
  }
}
</script>

<style lang="less" scoped>
.company-count-container {
  height: 100%;
  position: relative;
  .empty{
    width: 100%;
    height: 100%;
    background-image: url('/images/nodata_margex.png');
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
  }
  ::v-deep {
    .ant-spin-nested-loading {
      position: relative;
      height: 100%;
      .ant-spin-container {
        width: 100%;
        height: 100%;
        .chart {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
