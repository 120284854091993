<template>
  <div class="no-pie-container" :style="{'font-size': fontSize + 'px'}">
    <a-spin :spinning="spinning">
      <div class="revenue-wrapper">
        <div class="revenue-item header">
          <div class="company-name titlecolor">预算平台</div>
          <div class="progress-box titlecolor">{{ getField(query.code) }}</div>
        </div>
        <div class="empty" v-if="revenueList.length <= 0"></div>
        <template v-else>
          <div class="revenue-item item" v-for="(item, index) in revenueList" :key="index">
            <div class="company-name concolor">
              <span class="color" :style="{ 'background': colorList[index] }"></span>
              <a-tooltip :title="item.platName">
                <div class="name-box" :title="item.platName">{{ item.platName || '其他' }}</div>
              </a-tooltip>
            </div>
            <div class="progress-box concolor">
              <span v-if="isMoney(query.code)">{{ moneyText }}</span>
              <span class="number">
                {{ item[query.code] }}{{ query.code === 'advEcpm' ? '' : '%' }}
              </span>
              <a-tooltip :title="`${getField(query.code)}：${query.code === 'advEcpm' ? '¥' : ''}${item[query.code]}${query.code === 'advEcpm' ? '' : '%'}`">
                <a-progress
                  status="active"
                  :format="(percent) => `${item[query.code]}${query.code === 'ecpm' ? '' : '%'}`"
                  :strokeColor="colorList[index]"
                  :showInfo="false"
                  :percent="item[query.code]"
                />
              </a-tooltip>
            </div>
          </div>
        </template>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { platData } from '@/apiForManage/dashboard'
import { numFormat } from '@/utils/dealNumber'
import autoFontSize from '@/mixins/autoFontSize'
import { mapState } from 'vuex'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  mixins: [autoFontSize, mixRateAndMoneyMethod],

  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      spinning: false,
      colorList: ['#61A4E4', '#16DBCC', '#FFBB38', '#FF82AC', '#61A4E4', '#7161C5'],
      revenueList: [],
      arrList: [
        { name: '竞胜率', id: 'bidSuccessRate', sum: 'sumBidSuccessRate' },
        { name: '点击率', id: 'clickRate', sum: 'sumClickRate' },
        { name: '利润率', id: 'profitRate', sum: 'sumProfitRate' },
        { name: 'eCPM', id: 'advEcpm', sum: 'sumAdvEcpm' }
      ]
    }
  },
  computed: {
    ...mapState({
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥'
    })
  },
  created () {},
  mounted () {},
  destroyed () {},
  watch: {
    query: {
      handler (val, oldVal) {
        this.$nextTick(() => {
          this.getRevenueData()
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    numFormat,
    getField (data) {
      const typeList = this.arrList
      const name = typeList.find((item) => item.id === data).name
      return name
    },
    async getRevenueData () {
      this.revenueList = []
      this.spinning = true
      const resp = await platData(this.query)
      const { data = {} } = resp
      this.revenueList = data.list || []
      this.spinning = false
    }
  }
}
</script>

<style lang="less" scoped>
.no-pie-container {
  font-family: PingFang SC, PingFang SC;
  height: 100%;
  padding: calc(14em / 14) 0;
  ::v-deep {
    .ant-spin-nested-loading {
      height: 100%;
      position: relative;
      .ant-spin-container {
        width: 100%;
        height: 100%;
        .revenue-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          .revenue-item {
            flex-grow: 1;
            position: relative;
            display: flex;
            border-bottom: 1px solid #eee;
            font-size: calc(14em / 14);
            .company-name {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 40%;
            }
            .titlecolor {
              font-weight: 500;
              color: #5A607F;
            }
            .concolor {
              font-weight: 400;
              color: #344563;
            }
            .progress-box {
              flex-grow: 1;
              width: 60%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              cursor: pointer;
              .number {
                width: 40%;
                margin-right: calc(16em / 14);
              }
            }
            &.header {
              height: calc(20em / 14);
              font-size: calc(14em / 14);
              .progress-box {
                width: 60%;
              }
              .company-name {
                text-align: left;
              }
            }
            &.item {
              .company-name {
                font-size: calc(14em / 14);
                justify-content: flex-start;
                .color {
                  width: calc(14em / 14);
                  height: calc(14em / 14);
                  border-radius: calc(4em / 14);
                  margin-right: calc(14em / 14);
                }
                .name-box {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  cursor: pointer;
                  text-align: left;
                }
              }
            }
          }
          .revenue-item:last-child {
            border-bottom: none;
          }
          .empty{
            width: 100%;
            height: 90%;
            background-image: url('/images/nodata_margex.png');
            background-size: 70%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
}
</style>
