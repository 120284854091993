<template>
  <div class="company-chart-container">
    <m-ratio-box class="ratio-box" :ratio="87">
      <div class="company-chart-top" :style="{'font-size': fontSize + 'px'}">
        <!-- Menu只适用流量排行和预算排行 -->
        <Menu title="预算排行" :type="type" @changeMenu="changeMenu" />
        <div class="company-chart-top-right">
          <a-range-picker
            class="date-picker"
            format="MM.DD"
            :placeholder="['Start', 'End']"
            v-model="dates"
            :allowClear="false"
            :disabledDate="disabledDate"
            @change="changeDate"
            size="small"
            ref="datepicker"
            :ranges="{
              今日: [initDate(0, 0).start, initDate(0, 0).end],
              昨日: [initDate(1, 1).start, initDate(1, 1).end],
              最近一周: [initDate(6, 0).start, initDate(6, 0).end],
              最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
            }"
            :align="{
              offset: ['-50px', '0px']
            }"
          >
            <a-icon slot="suffixIcon" type="down" class="suffixIcon" />
          </a-range-picker>
          <a-select
            class="search_item"
            v-model="currentType"
            @change="changeTypes"
          >
            <a-select-option
              v-for="(child, index) in typeList"
              :key="index"
              :value="child.id"
            >
              {{ child.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="company-chart-content">
        <template v-if="!isPie(currentType) && listType === 0">
          <ListChart
            v-bind="{
              ...$attrs,
              query: { dateStart, dateEnd, code: currentType }
            }"
          />
        </template>
        <template v-if="isPie(currentType) && listType === 0">
          <PieChart
            v-bind="{
              ...$attrs,
              query: { dateStart, dateEnd, code: currentType }
            }"
          />
        </template>
        <template v-if="listType === 1">
          <listDisplay
            :type="2"
            v-bind="{
              ...$attrs,
              formquery: { dateStart, dateEnd, code: currentType }
            }"
          />
        </template>
      </div>
    </m-ratio-box>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import PieChart from './PieChart'
import ListChart from './listChart'
import listDisplay from '../../components/listDisplay'
import Menu from '../../components/menu'
import { getDurations } from '@/utils/duration'
import { typeList } from './list'
import { mapState } from 'vuex'
import autoFontSize from '@/mixins/autoFontSize'
export default {
  mixins: [mixDate, autoFontSize],
  components: { PieChart, ListChart, listDisplay, Menu },
  data () {
    return {
      currentType: 'advPrice',
      currentName: '收入',
      listType: 0,
      type: 0, // 0图表 1列表
      typeList,
      dates: [],
      dateStart: '',
      dateEnd: ''
    }
  },
  props: {
    pos: {
      default: '',
      type: String
    }
  },
  created () {
    this.changeTypes()
    const { end, start, endDate, startDate } = this.initDate(1, 1)
    this.dates = [start, end]
    this.dateStart = startDate
    this.dateEnd = endDate
  },
  computed: {
    ...mapState({
      sysUserDashboardSetting: (state) => state.user.sysUserDashboardSetting
    })
  },
  mounted () {
  },
  methods: {
    changeTypes () {
      this.currentName = this.typeList.find((item) => item.id === this.currentType).name
    },
    changeMenu (data) {
      this.listType = data
    },
    // 是否是饼图
    isPie (prop) {
      const rotaPropList = [
        'advPrice',
        'impress',
        'click'
      ]
      return rotaPropList.includes(prop)
    },
    changeDate (date, dateStr) {
      this.dateStart = date[0].format('YYYY-MM-DD')
      this.dateEnd = date[1].format('YYYY-MM-DD')
    },
    changeType (item) {
      this.currentType = item.id
      this.currentName = item.name
    },
    // 不可选时间
    disabledDate (current) {
      // 默认小于今天的时间 大于2023.10
      return (current && current < this.initDate(getDurations(), 1).start) || current >= this.initDate(0, 0).end
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.company-chart-container {

  .ant-calendar-picker-input.ant-input {
    padding-left: 0!important;
  }
  .ant-calendar-range-picker-separator {
    vertical-align: baseline;
  }
  .ant-calendar-range-picker-input {
    width: 40%;
  }
  .ant-calendar-picker .ant-calendar-picker-input.ant-input.ant-input-sm {
    height: 36px!important;
  }
}
.company-chart-container {
  width: calc(32.8% - 12em / 14);
  border-radius: @mediumRadius;
  background: #fff;
  padding: @mediumMargin;
  box-shadow: @modelShadow;
  border: 1px solid #D9E1EC;
  .search_item {
    width: calc(120em / 14);
  }
  .company-chart-top {
    display: flex;
    justify-content: space-between;
    padding-left: calc(6em / 14);
    .company-chart-top-right {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      vertical-align: middle;
      margin-top: calc(-8em / 14);
      .date-picker {
        margin-right: @compMargin;
        width: calc(160em / 14);
      }
      .suffixIcon {
        font-size: calc(12em / 14);
      }
    }
  }
  .company-chart-content {
    height: 100%;
    position: relative;
  }
}
</style>
