<template>
  <div class="item_menu" :style="{'font-size': fontSize + 'px'}">
    <el-dropdown @command="clickMenu" @visible-change="visibleChange" class="menu_dropdown">
      <span class="current-type">
        <HomeTitle class="title" :title="title" />
        <Arrow :class="['arrow',visible ? 'arrow_up' : 'arrow_down']" />
      </span>
      <el-dropdown-menu slot="dropdown" class="menu_dropdown" placement="bottom-start">
        <el-dropdown-item
          v-for="(item, index) in sectleList"
          :key="index"
          :command="item.id"
          class="menu-item"
          :style="[
            listTypes === item.id ? { background: '#6554C0', color: '#FFF', fontWeight: 600 } : {},
          ]">
          {{ item.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import autoFontSize from '@/mixins/autoFontSize'
import HomeTitle from './homeTitle'
import Arrow from '@/assets/icons/arrow.svg?inline'
export default {
  mixins: [autoFontSize],
  components: { HomeTitle, Arrow },

  props: {
    title: {
      default: '',
      type: String
    },
    type: {
      default: 0,
      type: Number
    }
  },
  data () {
    return {
      visible: false,
      listTypes: 0,
      sectleList: [
        { name: '图表', id: 0 },
        { name: '列表', id: 1 }
      ]
    }
  },
  methods: {
    visibleChange () {
      this.visible = !this.visible
    },
    clickMenu (data) {
      this.listTypes = data
      this.$emit('changeMenu', data)
    }
  }
}
</script>

<style lang="less" scoped>

.item_menu {
  min-width: calc(100em / 14);
  .current-type {
    font-weight: 600;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    .arrow {
      width: calc(16em / 14);
      height: calc(16em / 14);
      transition: all 0.5s;
    }
    .arrow_up {
      transform: rotate(180deg);
    }
  }
}
::v-deep.el-popper.menu_dropdown {
    border-radius: 4px!important;
    color: @defaultFontColor!important;
    width: calc(100em / 14);
      .el-dropdown-menu__item {
        width: 100%;
        text-align: center;
        color: @defaultFontColor;
      }
      .popper__arrow {
        display: none;
      }
      .el-dropdown-menu__item:not(.is-disabled):hover {
        background-color: fade(@primary-color, 10%);
        color: @defaultFontColor;
      }
}
</style>
